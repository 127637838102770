import React, { Component } from "react";

import Header from "../components/HeaderSection/Header";
import HeroSection from "../components/HeroSection/HeroOne";
import ServiceSection from "../components/ServiceSection/ServiceTwo";
import DiscoverSection from "../components/DiscoverSection/DiscoverTwo";
import Work from "../components/WorkSection/Work";
import ScreenshotSection from "../components/ScreenshotSection/ScreenshotsOne";
import FaqSection from "../components/FaqSection/FaqTwo";
import FooterSection from "../components/FooterSection/Footer";

class ThemeTwo extends Component {
  render() {
    return (
      <div className="homepage-2">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-colored.png"} />
          <HeroSection />
          <DiscoverSection />
          <ServiceSection />
          <ScreenshotSection />
          <FaqSection />
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default ThemeTwo;
