import Venue from "../../modules/venue";
export const ADD_VENUE = "ADD_VENUE";

export const addVenue = (
  sportIds,
  title,
  imageUrl,
  rating,
  numberOfRatings,
  address,
  lat,
  lng,
  openingHours,
  phoneNumber,
  available
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const verified = false;
    let ids = {};
    sportIds.forEach((element) => {
      ids[[element.value]] = element.bool;
    });

    const response = await fetch(
      `https://infieldapp-a0cfb-default-rtdb.europe-west1.firebasedatabase.app/venues.json?auth=${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sportIds: ids,
          title,
          imageUrl,
          rating,
          numberOfRatings,
          address,
          lat,
          lng,
          openingHours,
          phoneNumber,
          available,
        }),
      }
    );

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.error.message;
      let message = "Something went wrong! venue";
      throw new Error(message);
    }

    const resData = await response.json();
    console.log("res", resData);
    dispatch({
      type: ADD_VENUE,
      venueId: resData.name,
      // venueData: {
      //   sportIds: resData.sportIds,
      //   title: resData.title,
      //   imageUrl: resData.imageUrl,
      //   rating: resData.rating,
      //   numberOfRatings: resData.numberOfRatings,
      //   address: resData.address,
      //   lat: resData.lat,
      //   lng: resData.lng,
      //   openingHours: resData.openingHours,
      //   phoneNumber: resData.phoneNumber,
      //   available: resData.available,
      // },
    });
  };
};
