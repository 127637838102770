import React, { Component } from "react";
import Header from "../HeaderSection/Header";
import * as authActions from "../../store/actions/auth";
import * as profileActions from "../../store/actions/profile";
import * as venueActions from "../../store/actions/venues";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Multiselect from "multiselect-react-dropdown";

const mapDispatchToProps = (dispatch, getState) => {
  return {
    auth: (data) => dispatch(authActions.signup(data.email, data.password)),
    venue: (data, options) =>
      dispatch(
        venueActions.addVenue(
          options,
          data.clubName,
          data.img,
          data.rating,
          data.numberOfRtings,
          data.address,
          data.lat,
          data.lng,
          data.openingHours,
          data.phoneNumber,
          true
        )
      ),
    profile: (data) =>
      dispatch(
        profileActions.addProfile(
          data.firstName,
          data.lastName,
          data.phoneNumber,
          data.email
        )
      ),
  };
};

const getDetails = async (id) => {
  const proxyurl = "https://cors-anywhere.herokuapp.com/";
  const response = await fetch(
    proxyurl +
      `https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyBcp6VqU1lzL5iAb2VXUEvej2GBRbcfK-o&place_id=${id}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.json();
  if (!response.ok) {
    throw new Error("Something went wrong!");
  }
  return resData;
};

const getImgage = async (src) => {
  const response =
    await `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${src}&key=AIzaSyBcp6VqU1lzL5iAb2VXUEvej2GBRbcfK-o`;
  return response;
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      firstName: "",
      lastName: "",
      clubName: "",
      email: "",
      address: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      gmapsLoaded: false,
      rating: "",
      openingHours: "",
      numberOfRtings: "",
      img: "",
      lat: "",
      lng: "",
      selectedValue: [],
    };
    this.options = [
      {
        name: "soccer",
        value: "-MdZ5LowUBNMSZAuizBr",
        id: 1,
        bool: false,
      },
      {
        name: "basketball",
        value: "-MdZ5VsawBTPfHNmM8b7",
        id: 2,
        bool: false,
      },
      {
        name: "tennis-ball",
        value: "-MdZ5YAGT163a9SGcd-_",
        id: 3,
        bool: false,
      },
      {
        name: "volleyball",
        value: "-MdZ5_z4zVoD4RUH2XBb",
        id: 4,
        bool: false,
      },
      {
        name: "badminton",
        value: "-MdZ5afFfNmQXmxk99ic",
        id: 5,
        bool: false,
      },
      {
        name: "golf",
        value: "-MdZ5bL2DyBxZl-XqRgA",
        id: 6,
        bool: false,
      },
      {
        name: "hockey-sticks",
        value: "-MdZ5baPm1gYcAcdo0fs",
        id: 7,
        bool: false,
      },
      {
        name: "football",
        value: "-MdZ5c7iAZ7U46UK4udS",
        id: 8,
        bool: false,
      },
    ];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDfWvHszec-W-_VasS2mfVWxskCknA4Mxw&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  handleChange(event) {
    switch (event.target.name) {
      case "firstName":
        this.setState({ firstName: event.target.value });
        break;
      case "lastName":
        this.setState({ lastName: event.target.value });
        break;
      case "clubName":
        this.setState({ clubName: event.target.value });
        break;
      case "email":
        this.setState({ email: event.target.value });
        break;
      case "address":
        this.setState({ address: event.target.value });
        break;
      case "phoneNumber":
        this.setState({ phoneNumber: event.target.value });
        break;
      case "password":
        this.setState({ password: event.target.value });
        break;
      case "confirmPassword":
        this.setState({ confirmPassword: event.target.value });
        break;
    }
  }

  onSelect = (event) => {
    this.options.forEach((element, index) => {
      event.forEach((x) => {
        if (x == element) {
          console.log(index);
          this.options[index].bool = true;
        }
      });
    });
  };

  onRemove = (event) => {
    this.options.forEach((element, index) => {
      event.forEach((x) => {
        if (x == element) {
          console.log(index);
          this.options[index].bool = false;
        }
      });
    });
  };

  handleSearchChange = (address) => {
    this.setState({ search: address });
  };

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.selectedValue);
    if (this.state.password !== this.state.confirmPassword) {
      alert("Passwords does not match");
    } else {
      try {
        await this.props.auth(this.state);
        await this.props.venue(this.state, this.options);
        await this.props.profile(this.state);
        alert("A registration was submitted!");
      } catch (err) {
        console.log(err);
      }
    }
  }

  handleSelect = async (address) => {
    this.setState({ search: address });

    const res = await geocodeByAddress(address)
      .then(async (results) => await getDetails(results[0].place_id))
      .catch((error) => console.error("Error", error));

    await this.updateData(res);
  };

  updateData = async (details) => {
    const url = await getImgage(details.result.photos[0].photo_reference);
    const loc = await geocodeByPlaceId(details.result.place_id).then(
      (results) => getLatLng(results[0])
    );
    console.log(loc.lng);

    this.setState({
      clubName: details.result.name,
      address: details.result.formatted_address,
      phoneNumber: details.result.formatted_phone_number,
      rating: details.result.rating,
      numberOfRtings: details.result.user_ratings_total,
      img: url,
      openingHours: details.result.opening_hours.weekday_text,
      lat: loc.lat,
      lng: loc.lng,
    });
  };
  render() {
    return (
      <div className="accounts inner-pages signup">
        <div className="main">
          <Header  imageData={"/img/logo-colored.png"} />
          <section
            id="home"
            className="section welcome-area h-100vh bg-overlay d-flex align-items-center"
          >
            <div className="container">
              <div className="row align-items-center justify-content-center pt-5">
                <div className="">
                  {/* Contact Box */}
                  <div className="pb-2">
                    {this.state.gmapsLoaded && (
                      <PlacesAutocomplete
                        value={this.state.search}
                        onChange={this.handleSearchChange}
                        onSelect={this.handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "form-control",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={suggestion.description}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    )}
                  </div>
                  <div className="bg-white rounded p-4 p-sm-4 mt-5 mt-lg-0 shadow-lg">
                    <form noValidate onSubmit={this.handleSubmit}>
                      <div className="contact-top">
                        <h3 className="contact-title">Sign Up</h3>
                        <h5 className="text-secondary fw-3 py-3">
                          Fill all fields to join FurrBud familly.
                        </h5>
                      </div>
                      <div className="row pb-2">
                        <div className="col-md-6">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            className="form-control"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            noValidate
                            value={this.state.firstName}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="email">Last Name</label>
                          <input
                            className="form-control"
                            placeholder="Last Name"
                            type="email"
                            name="lastName"
                            noValidate
                            value={this.state.lastName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="row pb-2">
                        <div className="col-md-6">
                          <label htmlFor="firstName">Club Name</label>
                          <input
                            className="form-control"
                            placeholder="Club Name"
                            type="text"
                            name="clubName"
                            noValidate
                            value={this.state.clubName}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="email">Email</label>
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            name="email"
                            noValidate
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row pb-2">
                        <div className="col-md-6">
                          <label htmlFor="email">Address</label>
                          <input
                            className="form-control"
                            placeholder="Address"
                            type="text"
                            name="address"
                            noValidate
                            value={this.state.address}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="email">Phone Number</label>
                          <input
                            className="form-control"
                            placeholder="Phone Number"
                            type="phone"
                            name="phoneNumber"
                            noValidate
                            value={this.state.phoneNumber}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row pb-2">
                        <div className="col-md-6">
                          <label htmlFor="password">Password</label>
                          <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            name="password"
                            noValidate
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="confirmpassword">
                            Confirm Password
                          </label>
                          <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            name="confirmPassword"
                            noValidate
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="confirmpassword">Select Sports</label>
                          <Multiselect
                            options={this.options}
                            selectedValues={this.state.selectedValue}
                            onSelect={this.onSelect}
                            onRemove={this.onRemove}
                            displayValue="name"
                          />
                        </div>
                      </div>
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label contact-bottom"
                          htmlFor="exampleCheck1"
                        >
                          <span className="d-inline-block mt-3">
                            By signing up, you accept our <a href="/#">Terms</a>{" "}
                            &amp; <a href="/#">Privacy Policy</a>
                          </span>
                        </label>
                      </div>
                      <div className="mb-3">
                        <input type="submit" value="Create Account" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Shape Bottom */}
            <div className="shape-bottom">
              <svg
                viewBox="0 0 1920 310"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className="svg replaced-svg"
              >
                <title>sApp Shape</title>
                <desc>Created with Sketch</desc>
                <defs />
                <g
                  id="sApp-Landing-Page"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="sApp-v1.0"
                    transform="translate(0.000000, -554.000000)"
                    fill="#FFFFFF"
                  >
                    <path
                      d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                      id="sApp-v1.0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(Signup);
