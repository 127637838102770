import { ADD_VENUE } from "../actions/venues";

const initialState = {
  venueId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_VENUE:
      return {
        venueId: action.venueId,
      };
    default:
      return state;
  }
};
