import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes

import HomePage from "../pages/homePage";
import Reviews from "../components/ReviewSection/Reviews";
import ComingSoon from "../components/InnerSection/ComingSoon";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import ContactPage from "../components/ContactSection/ContactPage";
import SignIn from "../components/Accounts/Login";
import SignUp from "../components/Accounts/Signup";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy"
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions"
import GetFurrbudApp from "../components/GetFurrbudApp/GetFurrbudApp";
import IdeBIdakTermsAndConditions from "../components/IdeBIdakTAC/TermsAndConditions";
import IdeBIdakPrivacyPolicy from "../components/IdeBIdakTAC/PrivacyPolicy";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route path="/faq" component={Faq} />
            <Route path="/error-page" component={ErrorPage} />
            <Route path="/contact-page" component={ContactPage} />
            <Route path="/Login" component={SignIn} />
            <Route path="/SignUp" component={SignUp} />
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/idebidak/privacy-policy" component={IdeBIdakPrivacyPolicy}/>

            <Route path="/terms-and-conditions" component={TermsAndConditions}/>
            <Route path="/idebidak/terms-and-conditions" component={IdeBIdakTermsAndConditions}/>
            <Route path="/get-furrbud-app" component={GetFurrbudApp}/>
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
