import React, { Component } from 'react';
import ContactForm from './ContactForm';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneContactSection";

class ContactPage extends Component {
    state = {
        data: {
            "heading": "Contact Us",
            "content": "For any inquiries, assistance, or collaboration opportunities, the FurrBud team is readily available to address your needs. Please feel free to reach out to us via email at moussa@furrbud.com, and our dedicated support staff will promptly respond to your queries. Alternatively, you may utilize the contact form on our website to provide specific details about your inquiry, allowing us to better understand and assist you effectively.",
        },
        iconList: [
            {
                "id": 1,
                "iconClass": "fas fa-home",
                "text": "Beirut, Lebanon"
            },
            {
                "id": 2,
                "iconClass": "fas fa-phone-alt",
                "text": "+961 76 793559"
            },
            {
                "id": 3,
                "iconClass": "fas fa-envelope",
                "text": "moussa@furrbud.com"
            }
        ]
    }
    render() {
        return (
            <div className="contact-page">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header  imageData={"/img/logo-colored.png"} />
                    <Breadcrumb title="Contact Us" />
                    <section id="contact" className="contact-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-10 col-lg-6">
                                    {/* Section Heading */}
                                    <div className="section-heading">
                                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-12 col-md-5">
                                    {/* Contact Us */}
                                    <div className="contact-us">
                                        <p className="mb-3">{this.state.data.content}</p>
                                        <ul>
                                            {this.state.iconList.map((item, idx) => {
                                                return(
                                                    <li key={`ci_${idx}`} className="py-2">
                                                        <a className="media" href="/#">
                                                            <div className="social-icon mr-3">
                                                                <i className={item.iconClass} />
                                                            </div>
                                                            <span className="media-body align-self-center">{item.text}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 pt-4 pt-md-0">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ContactPage;