import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneFaqSection";

class FaqSection extends Component {
    state = {
        data: {
            "heading":"Frequently asked questions",
            "headingTwo":"Have questions? Look here",
            // "headingText":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
            // "headingTexttwo":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
            "faqText":"Haven't find suitable answer?",
            "faqTextLink":"Tell us what you need.",
         },
        faqDataThree: [
            {
               "id":1,
               "title":"How to install FurrBud?",
               "content":"The application is accessible on both the Apple App Store and Google Play Store. Please select the \"Download App\" icon located below to initiate the download process."
            ,
            "contentCollapse":"collapseOne",
            },
            {
               "id":2,
               "title":"How can I adopt a pet?",
               "content":"Download the application, explore and identify your prospective companion, establish an account, and submit an adoption request for the desired pet. Upon acceptance of your request by the other party, you can initiate contact, and they will facilitate the adoption process."
            ,
            "contentCollapse":"collapseTwo",
            },
            {
               "id":3,
               "title":"How can I report a missing pet?",
               "content":"Download the application, navigate to the \"Lost and Found\" section, select \"Report Missing Pet\", create an account, input all pertinent details, and subsequently publish your post on the application. The provided information will be disseminated to all app users, predominantly comprised of pet rescuers and organizations. Prompt contact will be initiated upon the identification of your pet by someone. Additionally, you may proactively search for your pet in the \"Found\" section, as someone might have already located and reported it through the application. Best of luck in your endeavor."
            ,
            "contentCollapse":"collapseThree",
            },
            {
               "id":4,
               "title":"How can i become a verified user?",
               "content":"To create an account on the application, kindly register, and subsequently, reach out to us at moussa@furrbud.com. Following this, we will conduct a verification process to ensure that your profile aligns with our stipulated criteria. Upon approval, your application will be sanctioned, and your account will be verified."
            ,
            "contentCollapse":"collapseFour",
            },
            {
               "id":5,
               "title":"Advantages of a Verified Account:",
               "content":"Prioritized Pet Listings: Your pet listings will receive priority placement in our search engine, enhancing visibility and reaching a broader audience. Enhanced Trust: A verified account carries a higher level of trust among our users, increasing the likelihood of prospective contacts reaching out to you."
            ,
            "contentCollapse":"collapseFive",
            }
         ]
    }
    render() {
        return (
            <section className="section faq-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.data.headingTwo}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-12">
                        {/* FAQ Content */}
                        <div className="faq-content">
                        {/* sApp Accordion */}
                        <div className="accordion" id="sApp-accordion">
                            <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8">
                                {/* Single Accordion Item */}
                                {this.state.faqDataThree.map((item, idx) => {
                                    console.log(item.id)
                                    return(
                                        <div key={`ft_${idx}`} className="card border-top-0 border-left-0 border-right-0 border-bottom">
                                            {/* Card Header */}
                                            <div className="card-header bg-inherit border-0 p-0">
                                                <h2 className="mb-0">
                                                <button className="btn px-0 py-3" type="button" data-toggle="collapse" data-target={`#${item.contentCollapse}`}>
                                                    {item.title}
                                                </button>
                                                </h2>
                                            </div>
                                            <div id={item.contentCollapse} className="collapse" data-parent="#sApp-accordion">
                                                <div className="card-body px-0 py-3">
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqSection;