import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import FooterSection from "../FooterSection/Footer";
import Breadcrumb from '../Blogs/Breadcrumb';

class PrivacyPolicy extends Component {
    state = {
        initData: []
    }
    render() {
        return (
            <div>
                                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header  imageData={"/img/logo-colored.png"} />
                    <Breadcrumb title="Privacy Policy" />
                    <div className="main">
                </div>
<h3 className="nav-link" style={{marginTop:'20px'}}>FurrBud Mobile Application Privacy Policy</h3>
<h5 className="nav-link" style={{marginTop:'20px'}}>Last Updated: December 05, 2023</h5>
    <p className="nav-link" style={{margin:'20px'}}>
    Welcome to FurrBud, a mobile application dedicated to helping users find and adopt pets while facilitating the reporting of lost and found pets in Lebanon. This Privacy Policy outlines how FurrBud collects, uses, and safeguards your personal information. Please read this policy carefully to understand our practices.
    </p>
<h5 className="nav-link">
1. Information We Collect
</h5>
<p className="nav-link" style={{margin:'20px'}}>
a. User-Provided Information: FurrBud may collect information directly from users during account creation, pet adoption inquiries, lost and found reports, and other interactions with the application.
</p>
<p className="nav-link" style={{margin:'20px'}}>
b. Automatically Collected Information: FurrBud may collect certain information automatically, such as device information, IP addresses, and usage patterns, to improve the functionality and user experience of the application.
</p>
<h5 className="nav-link">
2. How We Use Your Information
</h5>
<p className="nav-link" style={{margin:'20px'}}>
a. Providing Services: FurrBud uses collected information to offer and enhance the adoption and lost and found services provided by the application.
</p>
<p className="nav-link" style={{margin:'20px'}}>
b. Communication: We may use your contact information to communicate with you regarding pet adoption inquiries, lost and found reports, and other relevant updates.
</p>
<p className="nav-link" style={{margin:'20px'}}>
c. Improving Services: FurrBud analyzes user data to enhance and personalize the application's features and services.
</p>
<h5 className="nav-link">
3. Information Sharing
</h5>
<p className="nav-link" style={{margin:'20px'}}>
a. With Consent: We may share information with your consent or as directed by you.
</p>
<p className="nav-link" style={{margin:'20px'}}>
b. Service Providers: FurrBud may engage third-party service providers to assist in providing services. These providers are obligated to maintain the confidentiality and security of your information.
</p>
<p className="nav-link" style={{margin:'20px'}}>
c. Legal Compliance: We may disclose information in response to legal requests, court orders, or to comply with applicable laws and regulations.
</p>
<h5 className="nav-link">
4. Your Choices
</h5>
<p className="nav-link" style={{margin:'20px'}}>
a. Account Information: Users can review and update their account information within the application.
</p>
<p className="nav-link" style={{margin:'20px'}}>
b. Communication Preferences: Users can manage their communication preferences and opt-out of certain communications.
</p>
<h5 className="nav-link">
5. Security Measures
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud employs industry-standard security measures to protect user information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
</p>
<h5 className="nav-link">
6. Children's Privacy
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
</p>
<h5 className="nav-link">
7. Changes to Privacy Policy
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud reserves the right to update this Privacy Policy. Users will be notified of any material changes, and continued use of the application constitutes acceptance of the updated policy.
</p>
<h5 className="nav-link">
8. Contact Us
</h5>
<h5 className="nav-link">
FurrBud
</h5>
<h5 className="nav-link">
Beirut, Lebanon
</h5>
<h5 className="nav-link">
moussa@furrbud.com
</h5>
<h5 className="nav-link">
+96176793559
</h5>
<h5 className="nav-link">
Thank you for using FurrBud!
</h5>
<FooterSection />
                </div>
            </div>
               
        );
    }
}

export default PrivacyPolicy;