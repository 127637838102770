import React from "react";
import MyRouts from "./routers/routes";
import { initializeApp } from "firebase/app";

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  );
}
const firebaseConfig = {
  apiKey: "AIzaSyCwNVVyfW9GHFHZFb6RDsoffwk-0ywrKSU",
  authDomain: "furrbudapp-dev.firebaseapp.com",
  databaseURL: "https://furrbudapp-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "furrbudapp-dev",
  storageBucket: "furrbudapp-dev.appspot.com",
  messagingSenderId: "726795200415",
  appId: "1:726795200415:web:0307d9f54123bc911bbfaa",
  measurementId: "G-24LQ3N6Y8P"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export default App;
