import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneFaqSection";

class FaqSection extends Component {
    state = {
        data: {heading: 'Frequently Asked Questions'},
        faqData: [
            {
               "id":2,
               "title":"How can I adopt a pet?",
                "content":`The application is accessible on both the Apple App Store and Google Play Store. Please click on the "Download App" icon below to initiate the download process`
            },
            {
               "id":3,
               "title":"How can I report a missing pet?",
               "content":`Download the application, search for and select your new best friend, create an account, and submit a pet adoption request. Once your request is accepted by the other party, you can initiate contact, and they will guide you through the adoption procedure.`
            }
         ],
        faqDataTwo: [
            {
                "id":1,
                "title":"How to install FurrBud?",
                "content":`Download the app, navigate to the "Lost and Found" tab, select "Report Missing Pet," create an account, upload all required information, and subsequently publish your post on the app. This information will be shared with all app users, primarily comprised of pet rescuers and organizations. You will be contacted once someone locates your pet. Additionally, you may search for your pet in the "Found" section, as someone may have already found and reported it in the app. Best of luck in your search!`
             },
            {
               "id":2,
               "title":"How can i become a verified user?",
                "content":`To register an account on the app, please proceed with the registration process and subsequently contact us at moussa@furrbud.com. Upon receiving your request, we will verify your profile. If your profile meets our requirements, we will approve your application and verify your account.`
            },
            {
               "id":3,
               "title":"Advantages of a Verified Account:",
                "content":`Benefits of having a verified account include priority placement for your pet listings in our search engine, ensuring your content reaches a broader audience. Moreover, a verified account is inherently trusted by our users, increasing the likelihood of them reaching out to you.`
            }
         ]
    }

    render() {
        return (
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{this.state.data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.faqData.map((item, idx) => {
                                            return(
                                                <div key={`fo_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.faqDataTwo.map((item, idx) => {
                                            return(
                                                <div key={`ft_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{this.state.data.faqText} <a href="/contact-page">{this.state.data.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqSection;