import React, { Component } from 'react';

class GetFurrbudApp extends Component {
    state = {};
    getMobileOperatingSystem() {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // Detect Android
        const isAndroid = /Android/.test(navigator.userAgent);
        
        // Example usage
        if (isIOS) {
            window.location.href = "https://apps.apple.com/lb/app/furrbud/id6473955482";
        } else if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.furrbud";
        } else {
            window.location.href = "https://furrbud.com";
        }
    }
    render() {
        this.getMobileOperatingSystem()
        return (
               <div></div>
        );
    }
}

export default GetFurrbudApp;