import React, { Component } from "react";
import axios from "axios";

class DiscoverSection extends Component {
  state = {
    data: {"heading":"Easily communicate with clients using sApp.","headingTwo":"Adoption","headingThree":"More productivity with less effort","headingText":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.","thumbOne":"/img/discover_thumb.png","thumbTwo":"/img/discover_thumb_3.png","discoverData":[{"id":1,"iconClass":"fas fa-circle","text":"Combined with a handful of model sentence structures looks reasonable."},{"id":2,"iconClass":"fas fa-circle","text":"Contrary to popular belief, Lorem Ipsum is not simply random text."},{"id":3,"iconClass":"fas fa-circle","text":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium."},{"id":4,"iconClass":"fas fa-circle","text":"All the Lorem Ipsum generators on the Internet tend to repeat necessary."}],"discoverIcon":[{"id":1,"iconClass":"fas fa-bell"},{"id":2,"iconClass":"fas fa-envelope-open"},{"id":3,"iconClass":"fas fa-video"}]},
    discoverData: [{"id":1,"iconClass":"fas fa-circle","text":"Organizations create and add pet listings using the mobile app."},{"id":2,"iconClass":"fas fa-circle","text":"Pet lovers search for a pet that best matches their personalit."},{"id":3,"iconClass":"fas fa-circle","text":"Pet lovers request adoption through the app."},{"id":4,"iconClass":"fas fa-circle","text":" An application is sent directly to the pet organization."},{"id":5,"iconClass":"fas fa-circle","text":"The organization will go through the different applications, then choose and accept the ideal applicant."},{"id":6,"iconClass":"fas fa-circle","text":"Organizations will contact the chosen pet lover with the amazing news."},{"id":7,"iconClass":"fas fa-circle","text":"Congrats a pet meets its new family!!"}],
    discoverIcon: [],
    text: "From the comfort of their personal mobile phones, pet lovers can search for a pet that best matches their personality. They can then reference a shelter’s web page and discover what services it offers."
  };

  render() {
    return (
      <section className="section discover-area bg-gray overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto text-center mt-5 mt-lg-0">
                <img style={{height:'70vh'}} src="/img/SC3.webp" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              {/* Discover Text */}
              <div className="discover-text px-0 px-lg-4 pt-4 pt-lg-0">
                <h2>{this.state.data.headingTwo}</h2>
                <p className="my-4">{this.state.text}</p>
                {/* Check List */}
                <ul className="check-list">
                  {this.state.discoverData.map((item, idx) => {
                    return (
                      <div key={`dt_${idx}`}>
                        <li className="py-2">
                          {/* List Box */}
                          <div className="list-box media">
                            <span className="icon align-self-center">
                              <i className={item.iconClass} style={{color:'#35589c', height: 10}} />
                            </span>
                            <span className="media-body pl-2">{item.text}</span>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
                <div className="icon-box d-flex mt-3">
                  {this.state.discoverIcon.map((item, idx) => {
                    return (
                      <div key={`il_${idx}`} className="service-icon pr-3">
                        <span>
                          <i className={item.iconClass} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DiscoverSection;
