import Profile from "../../modules/profile";
export const ADD_PROFILE = "ADD_PROFILE";

export const addProfile = (
  firstName,
  lastName,
  phoneNumber,
  email,
  type = "owner"
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const verified = false;
    const userId = getState().auth.userId;
    const venueId = getState().venue.venueId;

    const repsonse = await fetch(
      `https://infieldapp-a0cfb-default-rtdb.europe-west1.firebasedatabase.app/profiles.json?auth=${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          venueId,
          firstName,
          lastName,
          phoneNumber,
          email,
          type,
          verified,
        }),
      }
    );
  };
};
