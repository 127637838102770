import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Breadcrumb extends Component {
    render() {
        return (
            <section className="section breadcrumb-area bg-overlay d-flex align-items-center" style={{height: 90}}>
            </section>
        );
    }
}

export default Breadcrumb;