import React, { Component } from "react";

class Header extends Component {
  render() {
    console.log(this.props)
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark" style={this.props.style}>
        <div className="container position-relative">
          <a className="navbar-brand" href="/">
            <img
            width={'150px'}
              className="navbar-brand-regular"
              src={this.props.imageData}
              alt="brand-logo"
            />
            <img
            width={'150px'}
              className="navbar-brand-sticky"
              src="/img/logo-colored.png"
              alt="sticky brand-logo"
            />
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner">
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav" id="navbar-nav">
                <li>
                  <a className="nav-link" href="/faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-page">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
