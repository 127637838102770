import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import FooterSection from "../FooterSection/Footer";
import Breadcrumb from '../Blogs/Breadcrumb';

class TermsAndConditions extends Component {
    state = {};
    
    render() {
        return (
                 <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header  imageData={"/img/logo-colored.png"} />
                    <Breadcrumb title="Terms and Conditions" />
                    <div className="main">
                </div>
<h3 className="nav-link" style={{marginTop:'20px'}}>
FurrBud Mobile Application Terms of Service and End-User License Agreement (EULA)
</h3>
<h5 className="nav-link" style={{marginTop:'20px'}}>Last Updated: January 08, 2024</h5>
    <p className="nav-link" style={{margin:'20px'}}>
    Welcome to FurrBud, a mobile application designed to connect users with potential pets for adoption and facilitate reporting of lost and found pets in Lebanon. Before you start using FurrBud, please read and understand the following Terms of Service and End-User License Agreement (EULA). By accessing or using the FurrBud mobile application, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, please do not use FurrBud.
</p>
<h5 className="nav-link" style={{marginTop:'20px'}}>End-User License Agreement (EULA)</h5>
<p className="nav-link" style={{margin:'20px'}}>
Before proceeding, you must agree to the End-User License Agreement (EULA). This agreement makes it clear that there is zero tolerance for objectionable content or abusive users. By accessing or using the FurrBud mobile application, you confirm your acceptance of the EULA.
</p>
<h5 className="nav-link">
1. Acceptance of Terms
</h5>
<p className="nav-link" style={{margin:'20px'}}>
By accessing or using the FurrBud mobile application, you agree to be bound by these Terms of Service and the End-User License Agreement (EULA). If you disagree with any part of these terms, you may not use FurrBud.
</p>
<h5 className="nav-link">
2. Description of Services
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud provides users with the ability to search for and adopt pets, as well as report lost and found pets in Lebanon. The application is available on iOS and Android platforms.
</p>
<h5 className="nav-link">
3. User Accounts
</h5>
<p className="nav-link" style={{margin:'20px'}}>
To access certain features of FurrBud, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.
</p>
<h5 className="nav-link">
4. Pet Adoption and Listings
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud facilitates the adoption of pets by connecting users with available pets in Lebanon. The application allows users to view pet listings, contact pet owners or adoption agencies, and submit adoption requests. FurrBud is not responsible for the accuracy of pet listings, and users are encouraged to verify information before proceeding with adoption.
</p>
<h5 className="nav-link">
5. Lost and Found Reports
</h5>
<p className="nav-link" style={{margin:'20px'}}>
Users can report lost and found pets using FurrBud. These reports are intended to facilitate the reunification of lost pets with their owners. FurrBud is not responsible for the outcome of lost and found reports and encourages users to contact local authorities and veterinary clinics.
</p>
<h5 className="nav-link">
6. User Conduct
</h5>
<p className="nav-link" style={{margin:'20px'}}>
Users are expected to use FurrBud in a responsible and lawful manner. Any misuse, including but not limited to false reporting, harassment, or any activity that violates applicable laws, is strictly prohibited.
</p>
<h5 className="nav-link">
7. Limitation of Liability
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud is provided "as is" and without any warranty. The application is not responsible for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of FurrBud.
</p>
<h5 className="nav-link">
8. Privacy Policy
</h5>
<p className="nav-link" style={{margin:'20px'}}>
The FurrBud Privacy Policy governs the use of personal information collected from users. By using FurrBud, you agree to the terms outlined in the Privacy Policy.
</p>
<h5 className="nav-link">
9. Modification of Terms
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud reserves the right to modify these Terms of Service and the End-User License Agreement (EULA) at any time. Users are encouraged to review the terms periodically. Continued use of FurrBud after modifications constitutes acceptance of the updated terms.
</p>
<h5 className="nav-link">
10. Governing Law
</h5>
<p className="nav-link" style={{margin:'20px'}}>
These Terms of Service and the End-User License Agreement (EULA) are governed by and construed in accordance with the laws of Lebanon. Any dispute arising out of or related to these terms shall be subject to the exclusive jurisdiction of the courts in Lebanon.
</p>
<h5 className="nav-link">
11. Content Policy and Zero Tolerance
</h5>
<p className="nav-link" style={{margin:'20px'}}>
FurrBud maintains a zero-tolerance policy for objectionable content or abusive users. Users agree not to upload, post, or transmit objectionable content. Violation of this policy may result in immediate account suspension or termination.
</p>
<h5 className="nav-link">
Contact Information:
</h5>
<h5 className="nav-link">
FurrBud
</h5>
<h5 className="nav-link">
Beirut, Lebanon
</h5>
<h5 className="nav-link">
moussa@furrbud.com
</h5>
<h5 className="nav-link">
+96176793559
</h5>
<h5 className="nav-link">
Thank you for using FurrBud!
</h5>
<FooterSection />
                </div>
            </div>
               
        );
    }
}

export default TermsAndConditions;