import React, { Component } from "react";
import Header from "../HeaderSection/Header";
import FooterSection from "../FooterSection/Footer";
import Breadcrumb from "../Blogs/Breadcrumb";

class IdeBIdakTermsAndConditions extends Component {
  state = {};

  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-colored.png"} />
          <Breadcrumb title="Terms and Conditions" />
          <div className="main"></div>
          <h3 className="nav-link" style={{ marginTop: "20px" }}>
            ideBidak Mobile Application Terms and Conditions
          </h3>
          <h5 className="nav-link" style={{ marginTop: "20px" }}>
            Effective Date: October 3, 2024
          </h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            Welcome to ideBidak! By downloading or using our app, you agree to
            the following terms and conditions. Please read these terms
            carefully.
          </p>
          <h5 className="nav-link">1. End-User License Agreement (EULA)</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            Before proceeding, you must agree to the End-User License Agreement
            (EULA). This agreement makes it clear that there is zero tolerance
            for objectionable content or abusive users. By accessing or using
            the ideBidak mobile application, you confirm your acceptance of the
            EULA. If you do not agree to this EULA, you may not use the app.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            <strong>EULA Overview:</strong>
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            Zero Tolerance for Objectionable Content: You must not post any
            content that is abusive, threatening, violent, discriminatory, or
            illegal. Violations will result in immediate suspension or permanent
            termination of your account.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            Abusive Users: Users found engaging in abusive behavior will be
            banned without notice.
          </p>
          <h5 className="nav-link">2. Use of the App</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            ideBidak allows users to post requests for help or offer assistance
            such as shelter, supplies, medical aid, and transportation.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            Users must provide accurate information and comply with all
            applicable laws when using the app.
          </p>
          <h5 className="nav-link">3. User Responsibilities</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            a. Accuracy: You are responsible for the accuracy of the information
            you provide.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            b. Interactions: You are responsible for your interactions with
            other users. Use caution when interacting with strangers.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            c. Misuse: You must not misuse the app by posting false, harmful, or
            illegal content.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            d. Objectionable Content: There is zero tolerance for objectionable
            content, including but not limited to harassment, hate speech,
            violent or abusive behavior, or any form of discriminatory language.
            Such behavior will result in immediate suspension or termination of
            your account.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            e. Abusive Users: If a user is reported or found to be engaging in
            abusive or threatening behavior, ideBidak reserves the right to ban
            or remove the user from the platform without prior notice.
          </p>
          <h5 className="nav-link">4. Limitation of Liability</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            ideBidak is a platform that connects users. We are not responsible
            for any harm, injury, or loss that occurs as a result of using the
            app. This includes but is not limited to:
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            a. Harm or injury caused by meeting someone through the app.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            b. Damage to property or loss of items.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            c. Any miscommunication or misinformation provided by users.
          </p>
          <h5 className="nav-link">5. No Warranty</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            The app is provided "as is" and without any warranties, express or
            implied. We do not guarantee that the app will be error-free,
            secure, or uninterrupted.
          </p>
          <h5 className="nav-link">6. Termination</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            We reserve the right to suspend or terminate your access to the app
            if you violate these terms, including but not limited to the
            submission of objectionable content or abusive behavior toward other
            users.
          </p>
          <h5 className="nav-link">7. Changes to the Terms</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            We may update these terms and conditions from time to time. Any
            changes will be posted in the app, and your continued use of the app
            after such changes indicates your acceptance.
          </p>
          <h5 className="nav-link">8. Contact Us</h5>
          <h5 className="nav-link">ideBidak</h5>
          <h5 className="nav-link">Beirut, Lebanon</h5>
          <h5 className="nav-link">moussa@furrbud.com</h5>
          <h5 className="nav-link">+96176793559</h5>

          <FooterSection />
        </div>
      </div>
    );
  }
}

export default IdeBIdakTermsAndConditions;
