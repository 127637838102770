import React, { Component } from "react";
class FooterSection extends Component {
  state = {
    data: {text:'Follow us on Social Media and show support!'},
    iconList: [
      {
          "id": 1,
          "link": "facebook",
          "iconClass": "fab fa-facebook-f",
          "href":"https://www.facebook.com/profile.php?id=61554541049009"
      },
      {
          "id": 3,
          "link": "google-plus",
          "iconClass": "fab fa-instagram",
          "href":"https://instagram.com/furrbudapp?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
      },
  ],
    footerList_1: [{id: 1, text: 'Home', link:'/#'},{id: 2, text: 'Contact', link:'/contact-page'}],
    footerList_2: [{id: 1, text: 'FAQ', link:'/faq'},{id: 2, text: 'Privacy Policy', link:'/privacy-policy'},{id: 3, text: 'Terms & Conditions', link:'/terms-and-conditions'}],
    footerList_3: [ {id: 1, image: '/img/google-play-black.png', link:'https://play.google.com/store/apps/details?id=com.furrbud'},{id: 2, image: '/img/app-store-black.png', link:'https://apps.apple.com/lb/app/furrbud/id6473955482'}],
  };

  render() {
    return (
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          {/* Footer Top */}
          <div className="footer-top ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="/#">
                      <img
                        className="logo"
                        src={this.state.data.image}
                        alt=""
                      />
                    </a>
                    <p className="mt-2 mb-3">{this.state.data.text}</p>
                    {/* Social Icons */}
                    <div className="social-icons d-flex">
                      {this.state.iconList.map((item, idx) => {
                        return (
                          <a target="_blank" key={`fi_${idx}`} className={item.link} href={item.href}>
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_1}
                    </h3>
                    <ul>
                      {this.state.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-2">
                            <a href={item.link}>{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_2}
                    </h3>
                    <ul>
                      {this.state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href={item.link}>{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_3}
                    </h3>
                    {/* Store Buttons */}
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {this.state.footerList_3.map((item, idx) => {
                        return (
                          <a key={`flth_${idx}`} href={item.link}>
                            <img src={item.image} alt="" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyrights 2023 FurrBud All rights reserved.
                    </div>
                    {/* Copyright Right */}
                    <div className="copyright-right">Made By FurrBud Team</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;
